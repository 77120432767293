// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
  }
  
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    font-family: "Open Sans", Arial, sans-serif;
    color: white;
    background: url('https://assets.mizora.jewelry/bg.jpg') no-repeat top/cover;
    background-size: cover;
  }
  
  /* body {
    background: #f0f0f0;
  } */
  
  .picker {
    position: absolute !important;
    top: 74px;
    left: 70px;
    width: 90px !important;
    height: 90px !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;EACxB;;EAEA;;;IAGE,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,YAAY;IACZ,2CAA2C;IAC3C,YAAY;IACZ,2EAA2E;IAC3E,sBAAsB;EACxB;;EAEA;;KAEG;;EAEH;IACE,6BAA6B;IAC7B,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,uBAAuB;EACzB","sourcesContent":["* {\n    box-sizing: border-box;\n  }\n  \n  html,\n  body,\n  #root {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    overflow: hidden;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    width: 100vw;\n    font-family: \"Open Sans\", Arial, sans-serif;\n    color: white;\n    background: url('https://assets.mizora.jewelry/bg.jpg') no-repeat top/cover;\n    background-size: cover;\n  }\n  \n  /* body {\n    background: #f0f0f0;\n  } */\n  \n  .picker {\n    position: absolute !important;\n    top: 74px;\n    left: 70px;\n    width: 90px !important;\n    height: 90px !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
