import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import './styles.css'

const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(rootElement);

root.render(
  <Router>
    <Routes>
      <Route path="/rings" element={<App />} />
      <Route path="/bracelets" element={<App />} />
      <Route path="/necklaces" element={<App />} />
      <Route path="/earrings" element={<App />} />
      <Route path="*" element={<App />} />
    </Routes>
  </Router>
);

