import React, { Suspense, useState, useEffect } from "react";
import * as THREE from "three";
import { ShaderMaterial } from "three";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import {
  PerspectiveCamera,
  Environment,
  OrbitControls,
  PresentationControls,
  AccumulativeShadows,
  RandomizedLight,
  Center,
  Html,
  useProgress,
} from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import { RGBELoader } from "three-stdlib";
import { getModelUrl } from "../utils/RingUrl";

function Loader() {
  const { progress } = useProgress();
  return (
    <Html center>
      <div
        style={{
          background: "rgba(0, 0, 0, 0.8)",
          padding: "20px 40px",
          borderRadius: "10px",
          color: "white",
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Load 3D Model... {Math.floor(progress)}%
      </div>
    </Html>
  );
}

function EnvironmentLoader({ onLoaded }) {
  const { gl } = useThree();
  const [loadingProgress, setLoadingProgress] = useState(0);

  const texture = useLoader(
    RGBELoader,
    "https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/brown_photostudio_02_1k.hdr",
    (loader) => {
      loader.manager.onProgress = (url, loaded, total) => {
        setLoadingProgress(Math.floor((loaded / total) * 100));
      };
    }
  );

  useEffect(() => {
    texture.mapping = THREE.EquirectangularReflectionMapping;
    onLoaded(texture);
    return () => {
      texture.dispose();
    };
  }, [texture, onLoaded]);

  return (
    <Html center>
      <div
        style={{
          background: "rgba(0, 0, 0, 0.8)",
          padding: "20px 40px",
          borderRadius: "10px",
          color: "white",
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Loading Environment... {loadingProgress}%
      </div>
    </Html>
  );
}

export default function RingScene({ modelType, modelName }) {
  const [ModelComponent, setModelComponent] = useState(null);
  const [environmentTexture, setEnvironmentTexture] = useState(null);

  useEffect(() => {
    let isMounted = true;

    getModelUrl(modelType, modelName).then((component) => {
      if (isMounted) {
        setModelComponent(() => component.default);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [modelType, modelName]);

  return (
    <Canvas shadows gl={{ pixelRatio: Math.min(window.devicePixelRatio, 2) }}>
      {!environmentTexture && (
        <EnvironmentLoader onLoaded={setEnvironmentTexture} />
      )}
      {environmentTexture && (
        <Suspense fallback={<Loader />}>
          <PerspectiveCamera makeDefault fov={50} position={[0, 150, 180]} />

          <ambientLight intensity={0.3} />
          <Environment map={environmentTexture} />

          <OrbitControls enablePan={true} enableZoom={true} />

          <PresentationControls
            global
            config={{ mass: 1, tension: 250, friction: 25 }}
            snap={{ mass: 2, tension: 250, friction: 50 }}
            zoom={1.25}
            rotation={[0.5, 0.5, 0]}
            polar={[-Math.PI / 5, Math.PI / 4]}
            azimuth={[-Math.PI / 1.75, Math.PI / 4]}
          >
            <group position={[0, -3, 0]}>
              <Center>
                {ModelComponent ? (
                  <ModelComponent
                    map={environmentTexture}
                    rotation={[-Math.PI / 2.05, 0, 0]}
                    scale={3}
                  />
                ) : (
                  <Html center>
                    <div
                      style={{
                        background: "rgba(0, 0, 0, 0.8)",
                        padding: "20px 40px",
                        borderRadius: "10px",
                        color: "white",
                        fontSize: "18px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Model not found
                    </div>
                  </Html>
                )}
              </Center>
              <AccumulativeShadows
                temporal
                frames={60}
                alphaTest={0.99}
                opacity={0.8}
                scale={20}
              >
                <RandomizedLight
                  amount={8}
                  radius={10}
                  ambient={0.5}
                  position={[0, 10, -2.5]}
                  bias={0.001}
                  size={3}
                />
              </AccumulativeShadows>
            </group>
          </PresentationControls>
          {/* <EffectComposer>
            <Bloom
              luminanceThreshold={1}
              intensity={0.10}
              levels={4}
              mipmapBlur
            />
          </EffectComposer> */}
        </Suspense>
      )}
    </Canvas>
  );
}
