export const ringModels = {
  sakura: () => import("../models/rings/SakuraRing"),
  ava: () => import("../models/rings/AvaRing"),
  lenka: () => import('../models/rings/LenkaRing'),
  lynn: () => import('../models/rings/LynnRing'),
  zahara: () => import('../models/rings/ZaharaRing'),
  eilaria: () => import('../models/rings/EilariaRing')
};

export const braceletModels = {
  felix: () => import("../models/bracelets/FelixBracelet"),
};

export const necklaceModels = {
  erina: () => import("../models/necklaces/ErinaNecklace"),
};

export const earringModels = {
  kinsey: () => import("../models/earrings/KinseyEarring"),
  arina: () => import("../models/earrings/ArinaEarring"),
  alyssa: () => import("../models/earrings/AlyssaEarring"),
  nola: () => import("../models/earrings/NolaEarring"),
  amari: () => import("../models/earrings/AmariEarring"),
  belle: () => import("../models/earrings/BelleEarring")
};

export function getModelUrl(type, modelName) {
const models =
  type === "rings"
    ? ringModels
    : type === "bracelets"
    ? braceletModels
    : type === "earrings"
    ? earringModels
    : necklaceModels;
return models[modelName]?.();
}
