import React from "react";
import { useLocation } from "react-router-dom";
import RingScene from "./components/RingScene";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function App() {
  const location = useLocation();
  const query = useQuery();

  const modelType = location.pathname.includes('bracelets') ? 'bracelets' :
                    location.pathname.includes('necklaces') ? 'necklaces' :
                    location.pathname.includes('earrings') ? 'earrings' : 'rings';

  const modelName = query.get("model") || "sakura";

  return <RingScene modelType={modelType} modelName={modelName} />;
}
